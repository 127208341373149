//
// _blog.scss
//

/*********************************/
/*         Blog                  */
/*===============================*/
.container-filter {
    li {
        margin: 0px 15px 10px !important;
        font-size: 16px; 
        font-weight: 600;
        letter-spacing: 0.8px;
        cursor: pointer;
        line-height: 34px;
        transition: all 0.5s ease;
        color: $muted !important;
        &.active,
        &:hover {
            color: $dark !important;
        }
    }

    &.filter-box {
        li {
            font-size: 14px;
            padding: 0px 15px; 
            margin: 5px 3px !important;
            font-weight: 500;
            font-family: $font-family-base;
            &.active,
            &:hover {
                color: $primary !important;
                border-color: $primary !important;
                background-color: rgba($primary, 0.1) !important;
            }
        }
    }
}


.portfolio {
    @each $name,
    $value in $colors {
        &.portfolio-#{$name} {
            .title,
            .lightbox-icon a {
                &:hover {
                    color: $value !important;
                }
            }

            .bg {
                background-color: $value !important;
            }

            .link {
                color: $value;
            }
            .img-bg {
                background-color: rgba($value, 0.15) !important;
            }

            .btn {
                background-color: #{$value} !important;
                border: 1px solid #{$value} !important;
                color: $white !important;
                box-shadow: 0 3px 5px 0 rgba($value, 0.1);
                &:hover, &:focus, &:active, &.active, &.focus{
                    background-color: darken($value, 10%) !important;
                    border-color: darken($value, 10%) !important;
                    color: $white !important;
                }
            }
        }
    }
}

.portfolio {
    img {
        transition: all 0.5s ease;
    }
    .title {
        transition: all 0.5s ease;
    }
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.5s ease;
        background-color: $dark;
    }
    .icons,
    .content {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
    }
    &:hover {
        .overlay {
            opacity: 0.6;
        }
        .icons,
        .content {
            opacity: 1;
        }
    }

    &.portfolio-grid {
        transition: all 0.5s ease;
        .grid-content {
            transition: all 0.5s ease;
        }
        .grid-content {
            position: absolute;
            bottom: 15px;
            right: 15px;
            left: 15px;
            transform: translateY(150px);
            overflow: hidden;
            .tag {
                font-size: 15px !important;
            }
        }
        &:hover { 
            box-shadow: $shadow;
            .grid-content {
                transform: translateY(0);
            }
        }
    }

    &.portfolio-modern {
        .port-image {
            z-index: 1;
            transition: all 0.5s ease;
            &:hover {
                transform: translate3d(-1px, -1px, 0) rotateZ(-2.5deg);
            }
        }
        .bg {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    &.portfolio-classic {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

//Tobii Lighbox
.tobii__btn svg {
    height: 30px;
    width: auto;
}
.tobii__counter {
    font-size: 16px;
}
.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: 0 !important;
}

//Resume

.timeline-page {
    &:after {
        position: absolute;
        content: "";
        left: 0;
        right: -1px;
        top: 0px;
        width: 1px;
        height: 100%;
        margin: auto;
        border-right: 2px dashed $gray-300 !important;
    }
    .timeline-item {
        .date-label-left, .duration-right {
            &:after,
            &:before {
                position: absolute;
                content: "";
                border-radius: 50%;
                z-index: 1;
            }
            &:after {
                top: 9px;
                width: 10px;
                height: 10px;
                background-color: $primary;
            }

            &:before {
                border: 2px dashed $gray-300;
                top: 0;
                width: 28px;
                height: 28px;
                background-color: $white;
            }
        }
        .date-label-left {            
            float: right;
            margin-right: 24px;
            &:after {                
                right: -41px;
            }

            &:before {                
                right: -50px;
            }
        }        
        .duration-right {
            float: left;
            margin-left: 24px;
            &:after {                
                left: -41px;
            }

            &:before {                
                left: -50px;
            }
        }
        .event {
            transition: all 0.2s ease;
            .title {
                font-weight: 500;
                transition: all 0.2s ease;
            }
            &.event-description-right {
                text-align: left;
                margin-left: 24px;
            }
            &.event-description-left {
                text-align: right;
                margin-right: 24px;
            }
        }
    }
}

@media (max-width: 575px) {
    .timeline-page {
        margin-left: 18px;
        &:after {
            margin: 0;
        }
        .timeline-item {
            .duration {
                float: left !important;
                margin-left: 30px !important;
                margin-right: auto !important;
                text-align: left !important;
                &:after {
                    left: -34px !important;
                }
                &:before {
                    left: -42px !important;
                }
                .event {
                    text-align: left !important;
                    margin-left: 15px;
                }
            }
            .event {
                &.event-description-left,
                &.event-description-right {
                    text-align: left !important;
                    margin: 0 0 0 30px !important;
                }
            }
        }
    }
}