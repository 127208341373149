//
// _contact.scss
//

/*********************************/
/*         Contact us            */
/*===============================*/
.form-icon {
    .icons {
        position: absolute;
        top: 13px;
        left: 18px;
    } 
}

.contact-show-btn {
    width: 100px;
    height: 100px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        background-color: $primary;
        border-radius: 50%;
        box-shadow: 0 10px 25px rgba($primary, 0.5);
    }
}

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message,
#success_page {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 5px;
}

.error_message {
    background-color: rgba($danger, 0.1) !important;
    border: 2px solid rgba($danger, 0.1) !important;
    color: $danger !important;
    font-size: 14px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    background-color: rgba($success, 0.1) !important;
    border: 2px solid rgba($success, 0.1) !important;
    color: $success !important;
    p {
        margin-bottom: 0 !important;
    }
}

//Job search form
.filter-search-form {
    .icons {
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        left: 12px;
        z-index: 1;
        color: $primary;
        font-size: 20px;
    }
}
.registration-form {
    position: relative;
    .submit-btn {
        border-radius: 8px;
    }
    .form-control,
    .choices[data-type*=select-one] .choices__inner {
        border-radius: 8px;
    }
}
@media (min-width:992px) {
    .filter-border {
        &:before {
            content: "";
            border-right: 1px solid $gray-200;
            position: absolute;
            right: 0px;
            height: 40px;
            top: 10px;
            z-index: 1;
        }
    }
    .registration-form {
        position: relative;
        .submit-btn {
            border-radius: 0px 6px 6px 0px;
        }
        .form-control {
            border-radius: 6px 0px 0px 6px;
        }
        .choices[data-type*=select-one] .choices__inner {
            border-radius: 0;
        }
    }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
    box-shadow: none !important;
    border: 0;
    font-size: 15px;
    height: 60px;
    padding: 13px 6px 15px 45px;
    border-radius: 0;
}

//Datepicker
.qs-datepicker-container {
    background-color: $white;
    border-color: $gray-300;
    .qs-controls {
        background-color: $gray-200;
        .qs-month-year {
            font-weight: 600;
            font-size: 15px;
            &:hover {
                border-bottom: 1px solid $gray-200;
            }
        }
    }
    .qs-squares {
        .qs-square {
            font-size: 15px;
            height: 34px;
            width: 34px;
            &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
            &.qs-current {
                background: $primary;
                color: $white;
                border-radius: 30px;
                font-weight: 500;
                text-decoration: none;
                border-radius: 30px;
            }
        }
        .qs-day {
            font-weight: 500;
            color: $muted;
        }
    }
}

/*Select 2*/ 
.css-13cymwt-control{
    border-color: transparent !important;
    border-style: none !important;
    background-color: $light !important;
  }
  
  .css-t3ipsp-control {
    border-color: transparent !important;
    background-color: $light !important;
  }
  
  .css-1u9des2-indicatorSeparator{
    display: none !important;
  }
  
  .css-1fdsijx-ValueContainer{
    padding: 0px !important;
  }
  
  .css-qbdosj-Input {
    margin: 0 !important;
    padding: 0px !important;
  }
  
  .css-13cymwt-control:hover,
  .css-13cymwt-control:focus,
  .css-t3ipsp-control:hover,
  .css-t3ipsp-control:focus{
    border-color: transparent !important;
    border-style: none !important;
    box-shadow: none !important;
    background-color: $light !important;
  }
  
  .css-1dimb5e-singleValue{
    color: $dark !important;
  }
  
  .css-1nmdiq5-menu{
    z-index: 999;
    border-radius: 5px;
    border-style: none !important;
    background-color: $white !important;
    box-shadow: $shadow;
    color: $dark !important;
  }
  .css-1nmdiq5-menu .css-1n6sfyn-MenuList::-webkit-scrollbar {
    display: none;
  }
  
  .css-13cymwt-control:after,
  .css-13cymwt-control:before{
    box-shadow: $shadow !important;
  }