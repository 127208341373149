//
// Bootstrap-custom.scss
//

/*********************************/
/*     Bootstrap-custom-DARK     */
/*===============================*/

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .bg-soft-#{$name} {
        background-color: rgba($value, 0.05) !important;
        color: #{$value} !important;
    }
    .bg-blur-#{$name} {
        backdrop-filter: blur(12px);
        background-color: rgba($value, 0.8);
    }
    .bg-lighten-#{$name} {
        background-color: lighten($value, 23%) !important;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: darken($value, 5%) !important;
            }
        }
    }
}

//Card
.card {
    color: $dark;
    background-color: $white;
}

//Offcanvas
.offcanvas {
    .offcanvas-header {
        .dark-version {
            display: inline-block;
        }
        .light-version {
            display: none;
        }
        .btn-close {
            color: $dark;
        }
    }
}

//Border
.border {
    border: 1px solid $gray-800 !important;
    &.border-light {
        border-color: $dark !important;
    }
}
.border-top {
    border-top: 1px solid $gray-800 !important;    
}
.border-bottom {
    border-bottom: 1px solid $gray-800 !important;    
}
.border-left {
    border-left: 1px solid $gray-800 !important;    
}
.border-right {
    border-right: 1px solid $gray-800 !important;    
}