//
// footer.scss
//

/*********************************/
/*         Footer                */
/*===============================*/

footer {
    position: relative;
    color: $gray-500;
    .footer-py-120 {
        padding: 120px 0;        
    }
    .footer-py-60 {
        padding: 60px 0;        
    }
    .footer-py-30 {
        padding: 30px 0;        
    }
    .footer-head {
        letter-spacing: 1px;         
        font-weight: 500;
        color: $light;
    }
    
    .text-foot {
        color: $gray-500;
    }

    .footer-subscribe, .foot-subscribe {
        .form-control {
            background-color: lighten($black, 10%);
            border: 1px solid lighten($black, 10%);
            color: $light !important;
        }
        input {
            padding: 9px 20px;
            padding-right: 48px !important;
            &::placeholder{
                color: $gray-500;
            }
        }
        button {
            top: 4px;
            right: 5px;
            &.btn {
                height: 44px;
                width: 44px;
            }
        }
        &.foot-white {
            .form-control {
                color: $gray-500;
            }
        }
    }
    
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a {
                transition: all 0.5s ease;
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }   
            &:last-child{
                margin-bottom: 0;
            }         
        }
    }

    .footer-border,
    .footer-bar {
        border-top: 1px solid lighten($footer, 3%);
    }
    .footer-border-bottom {
        border-bottom: 1px solid lighten($footer, 3%);
    }
    .border {
        border-color: lighten($footer, 3%) !important;
    }
}