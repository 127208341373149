//
// menu.scss
//

/*********************************/
/*     Menu-DARK                 */
/*===============================*/
#topnav {
    .logo {
        color: $dark !important;
        .l-dark,
        .logo-light-mode {
            display: none !important;
        }
        .l-light,
        .logo-dark-mode {
            display: inline-block !important;
        }
    }
    .has-submenu{
        .submenu {
            .submenu-arrow {
                border: solid $dark;
                border-width: 0 2px 2px 0;
            }
        }
    }
    .navbar-toggle {
        span {
            background-color: $dark;
        }
    }
    
    .navigation-menu {
        > li {
            > a {
                color: $dark;
                &:hover,
                &.active {
                    color: $primary !important;
                }
            }
            .submenu {
                &.megamenu {
                    li {
                        .megamenu-head {
                            color: $dark !important;
                        }
                    }
                }
            }
        }
        
        .has-submenu {
            .menu-arrow {
                border: solid $dark;
                border-width: 0 2px 2px 0;
            }
        }
    }
    
    &.scroll {
        background-color: $white;
        box-shadow: $shadow;
        .navigation-menu {
            > li {
                > a {
                    color: $dark;
                }
                
                > .menu-arrow {
                    border-color: $dark;
                }
            } 
        }
    }

    &.nav-sticky{
        background: $white;
        box-shadow: $shadow;
        .navigation-menu {
            &.nav-light {
                > li {
                    > a {
                        color: $dark !important;
                    }
                }
                .has-submenu {
                    .menu-arrow {
                        border-color: $dark;
                    }
                }
            }
        }
        .logo {
            &.logo-light {
                color: $dark !important;
            }
        }
    }
}

@media (min-width: 992px) {
    #topnav {
        .navigation-menu {
            > li {
                .submenu {
                    background-color: $white;
                    box-shadow: $shadow;
                    li {
                        a {
                            color: $dark !important;
                        }
                    }
                }
            }
        } 
    }
}
  
@media (max-width: 991px) {
    #topnav {
        background-color: $white;
        box-shadow: $shadow;
        .navigation-menu {
            > li {
                .submenu {
                    li {
                        a {
                            color: $dark !important;
                        }
                    }
                    &.megamenu {
                        > li {
                            > ul {
                                > li {
                                    > span {
                                        color: $muted;
                                    }
                                }
                            }
                        }
                    }
                }
                > a {
                    color: $dark;
                }
            }
        }
        .menu-extras .menu-item {
            border-color: $muted;
        }
    }

    #navigation {
        border-top: 1px solid darken($light,2%);
        border-bottom: 1px solid darken($light,2%);
        background-color: $white;
    }
}

//Sidebar-nav 
.sidebar-nav{
    > .navbar-item {
        .navbar-link {
            color: $muted !important;
        }
    }
}