//
// _photography.scss
//

/*********************************/
/*         Photography           */
/*===============================*/
.photography-scss {
    background: $black;
    #topnav {
        &.nav-sticky {
            background: $black;
        }
    }
    .bg-white,
    .card {
        background: $black !important;
    }
    .border-top,
    .border-right,
    .border-bottom,
    .border-left {
        border-color: lighten($black, 5%) !important;
    }
    .bg-linear-gradient-2 {
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.3) 50%, rgba($black, 0.7) 75%, rgba($black, 1) 100%);
    }
    .bg-linear-gradient-3 {
        background: linear-gradient(to right, rgba($black, 1) 0%, rgba($black, 1) 25%, rgba($black, 1) 50%, rgba($black, 0.5) 75%, rgba($black, 0) 100%);
    }
    .photo-phase {
        .bg-overlay {
            transform: translateY(20px);
            transition: all 0.5s ease;
        }
        &:hover {
            .bg-overlay {
                transform: translateY(0);                
            }
            .link {
                color: $dark !important;
            }
        }
    }

    .portfolio {
        &.photo-showcase {
            .overlay {
                background: $black !important;
            }
        }
    }

    //Portfolio
    .container-filter {
        li {
            color: rgba($dark, 0.5) !important;
            &.active,
            &:hover {
                color: $dark !important;
            }
        }
        .categories {
            &:after {
                background-color: rgba($primary, 0.4);
            }
        }
    }

    //Social icons
    .social-icon,
    .social-icon .social {
        li {
            a {
                color: $gray-300;
                border: 1px solid $gray-300;
                &:hover {
                    color: $dark !important;
                }
            }
        }
    }
    .social-icon {
        &.foot-social-icon {
            li {
                a {
                    color: $gray-300;
                    border-color: lighten($black, 5%);
                }
            }
        }
        &.social {
            li {
                a {
                    color: darken($dark, 10%);
                    border: 1px solid lighten($black, 10%);
                    &:hover {
                        color: $dark !important;
                    }
                }
            }
        }
    }

    footer {
        .footer-bar {
            border-top: 1px solid lighten($black, 10%) !important;
        }
    }
}