//
// _gym.scss
//

/*********************************/
/*         gym                   */
/*===============================*/
body {
    &.gym-scss {
        font-family: "Ubuntu", sans-serif !important;
        font-size: 16px;
        background: $black;
    }
}

.gym-scss {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: "Teko", sans-serif !important;
        line-height: 1.2 !important;
        font-weight: 500 !important;
    }

    #topnav .navigation-menu > li > a{
        font-family: "Teko", sans-serif !important;
    }

    .bg-white,
    .card {
        background: $black !important;
    }
    
    .border-top,
    .border-right,
    .border-bottom,
    .border-left {
        border-color: lighten($black, 5%) !important;
    }

    .bg-overlay {
        background-color: rgba($white, 0.8) !important;
    }

    .bg-linear-gradient-2 {
        background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, 0.3) 50%, rgba($black, 0.7) 75%, rgba($black, 1) 100%);
    }

    .bg-linear-gradient-3 {
        background: linear-gradient(to right, rgba($black, 1) 0%, rgba($black, 1) 25%, rgba($black, 1) 50%, rgba($black, 0.5) 75%, rgba($black, 0) 100%);
    }

    //Social icons
    .social-icon,
    .social-icon .social {
        li {
            a {
                color: $gray-300;
                border: 1px solid $gray-300;
                &:hover {
                    color: $dark !important;
                }
            }
        }
    }
    .social-icon {
        &.foot-social-icon {
            li {
                a {
                    color: $gray-300;
                    border-color: lighten($black, 5%);
                }
            }
        }
        &.social {
            li {
                a {
                    color: darken($dark, 10%);
                    border: 1px solid lighten($black, 10%);
                    &:hover {
                        color: $dark !important;
                    }
                }
            }
        }
    }

    footer {
        &.bg-footer {
            background: lighten($black, 3%) !important;
        }
        .footer-bar {
            border-top: 1px solid lighten($black, 10%) !important;
        }
    }
}