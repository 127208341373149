//
// helper.scss
//

/*********************************/
/*     Helper-DARK               */
/*===============================*/

.bg-overlay {
    background: $overlay;
}

.bg-overlay-white {
    background: $bg-overlay-white;
}

.bg-gradient-primary {
    background: $bg-gradient-primary;
}

.bg-linear-gradient {
    background: $linear-gradient;
}

.bg-linear-gradient-2 {
    background: $linear-gradient-2;
}

.bg-linear-gradient-3 {
    background: $linear-gradient-3;
}

.bg-gradient-overlay {
    background: $gradient-overlay;
}

.bg-gradient-overlay-2 {
    background: $gradient-overlay-2;
}

.bg-primary-gradient-overlay {
    background: $primary-gradient-overlay;
}

.bg-gradient-white-overlay {
    background: $gradient-white-overlay;
}

.text-shadow-title {
    text-shadow: 2px 0 0 $dark, -2px 0 0 $dark, 0 4px 0 rgba($dark, 0.4), 0 -2px 0 $dark, 1px 1px $dark, -1px -1px 0 $dark, 1px -1px 0 $dark, -1px 1px 0 $dark;
}

//Title
.title-dark {
    color: $dark !important;
}
.title-dark-50 {
    color: rgba($dark, 0.5) !important;
}
.para-dark {
    color: $muted !important;
}
.title-white {
    color: $white !important;
}
.title-bg-dark {
    background: $dark !important;
}

.bg-white-dark {
    background: $white !important;
}


//Shapes
.shape {
    &.crypto-bottom {
        bottom: 0 !important;
        .crypto-border {
            border-top: 1px dashed rgba($dark, 0.2);
        }
    }
}

.home-shape-arrow {
    i {
        background: $white;
        box-shadow: $shadow;
    }
}

//CTA
.play-btn {
    color: $white;
}