//
// _about.scss
//

/*********************************/
/*         About Us              */
/*===============================*/
.about-image {
    .about-image-position {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}